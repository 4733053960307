<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <div v-if="message" class="alert alert-info mb-10" role="alert">
            {{ message }}
          </div>
          <Form
            @submit="handleSubmit"
            :validation-schema="schema"
            class="form w-100"
            novalidate="novalidate"
            id="kt_sign_in_form"
          >
            <div class="mb-5">
              <label for="">NIK</label>
              <Field
                type="text"
                name="nik"
                class="form-control bg-transparent"
                v-model="formInput.nik"
              />
              <ErrorMessage name="nik" class="text-danger error-feedback" />
            </div>
            <div class="mb-5">
              <label for="">Passphrase</label>
              <Field
                type="text"
                name="passphrase"
                class="form-control bg-transparent"
                v-model="formInput.passphrase"
              />
              <ErrorMessage
                name="passphrase"
                class="text-danger error-feedback"
              />
            </div>
            <div class="mb-5">
              <label for="">File .pdf</label>
              <Field
                type="file"
                name="files"
                class="form-control bg-transparent"
                v-model="formInput.files"
                accept="application/pdf"
              />
              <ErrorMessage name="files" class="text-danger error-feedback" />
            </div>

            <div>
              <button class="btn btn-secondary" @click="$router.go(-1)">
                Back
              </button>
              <button
                :disabled="loading"
                type="submit"
                id="kt_sign_in_submit"
                class="btn btn-success"
              >
                <span class="indicator-label">Submit</span>
                <span class="indicator-progress" v-show="loading">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  >
                  </span>
                </span>
              </button>
            </div>
          </Form>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Post-->
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Service from "../../../services/base.service";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const MAX_FILE_SIZE = 1024000;

    const validFileExtensions = {
      image: ["pdf"],
    };

    const isValidFileType = (fileName, fileType) => {
      return (
        fileName &&
        validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
      );
    };

    const schema = yup.object().shape({
      nik: yup.string().required("nik is required!"),
      passphrase: yup.string().required("passphrase is required!"),
      files: yup
        .mixed()
        .required("Required")
        .test("is-valid-type", "Not a valid pdf file", (value) =>
          // TODO: VALIDATE MULTIPLE FILES
          isValidFileType(value && value.name.toLowerCase(), "image")
        )
        .test("is-valid-size", "Max allowed size is 1MB", (value) => {
          /*
          if (value) {
            const haveMaxVileSize = value.filter((v) => {
              console.log(v.size);
              return v && v.size > MAX_FILE_SIZE;
            });
            console.log(value, haveMaxVileSize);
            return haveMaxVileSize.length > 0;
          }

          return false;
          // console.log(value);
          */
          return value && value.size <= MAX_FILE_SIZE;
        }),
      //   callback: yup.string().required("status is required!"),
    });

    return {
      loading: false,
      message: "",
      formInput: {
        nik: "",
        passphrase: "",
        files: null,
      },
      schema,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchData();
    }
    // this.getListData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const BaseService = new Service("esign-service/request");
        const { data } = await BaseService.getData(this.$route.params.id);
        this.formInput = data;
        // this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(data) {
      const formData = new FormData();
      formData.append("nik", data.nik);
      formData.append("passphrase", data.passphrase);
      formData.append("files", data.files);
      formData.append("service_id", this.$route.params.serviceId);

      this.loading = true;
      const BaseService = new Service("esign-service/request");
      try {
        // const { message } = this.$route.params.id
        //   ? await BaseService.updateData(this.$route.params.id, data)
        //   : await BaseService.createData(data);
        const { message } = await BaseService.uploadFile(formData);
        this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
